import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

interface User {
  userId: string;
  givenName: string;
  surName: string;
}

interface SelectUsersModalProps {
  open: boolean;
  handleClose: () => void;
  users: User[];
  teamData?: any;
  onSave: (selectedUsers: User[]) => void;
  selectedUsers: User[];
}

const SelectUsersModal: React.FC<SelectUsersModalProps> = ({
  open,
  handleClose,
  users,
  teamData,
  selectedUsers: initialSelectedUsers,
  onSave,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const isAllSelected = selectedUsers.length === users.length;

  useEffect(() => {
    if (open) {
      setSelectedUsers(initialSelectedUsers);
    } else {
      setSelectedUsers([]);
    }
  }, [open, initialSelectedUsers]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleUser = (user: User) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.some((u) => u.userId === user.userId)
        ? prevSelected.filter((u) => u.userId !== user.userId)
        : [...prevSelected, user]
    );
  };

  const handleSave = () => {
    onSave(selectedUsers);
    handleClose();
  };

  const handleToggleSelectAll = () => {
    setSelectedUsers(isAllSelected ? [] : users);
  };

  const filteredUsers = users.filter((user) =>
    `${user.givenName} ${user.surName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Modal open={open} onClose={handleClose} style={{ overflow: "auto" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          width: "520px",
          mx: "auto",
          mt: "5vh",
          padding: "40px 20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">
            {t("teamManagement.selectUsersModalTitle")}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div>

        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("teamManagement.searchPlaceholder")}
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ margin: "20px 0" }}
        />
        <Box
          sx={{ display: "flex", justifyContent: "end", marginBottom: "20px" }}
        >
          <Button
            onClick={handleToggleSelectAll}
            variant="outlined"
            color="primary"
          >
            {isAllSelected
              ? t("teamManagement.unselectAllUsersButton")
              : t("teamManagement.selectAllUsersButton")}
          </Button>
        </Box>
        <List
          style={{
            height: "467px",
            overflowY: "scroll",
          }}
        >
          {filteredUsers.map((user) => (
            <ListItem
              key={user.userId}
              button
              onClick={() => handleToggleUser(user)}
            >
              <Avatar sx={{ marginRight: "10px" }}>
                {user.givenName.charAt(0)}
              </Avatar>
              <ListItemText primary={`${user.givenName} ${user.surName}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  checked={selectedUsers.some((u) => u.userId === user.userId)}
                  onChange={() => handleToggleUser(user)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button onClick={handleSave} variant="contained" color="primary">
              {t("teamManagement.saveButton")}
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              style={{ marginLeft: "10px" }}
            >
              {t("teamManagement.cancelButton")}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectUsersModal;
